import React from "react";
import CamisaBranca from "../imagens/camisetaBrancaDestaque.png";
import CamisaIcon from "../imagens/camisaIcon.png"; // Supondo que você tenha esses ícones
import CalcaIcon from "../imagens/calcaIcon.png";
import AcessorioIcon from "../imagens/acessorioIcon.png";

function Destaques() {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Destaques</h1>

      <div style={styles.imagesContainer}>
        <div style={styles.card}>
          <img src={CamisaBranca} alt="Camisa Branca" style={styles.image} />
        </div>
        <div style={styles.card}>
          <img src={CamisaBranca} alt="Camisa Cinza" style={styles.image} />
        </div>
        <div style={styles.card}>
          <img src={CamisaBranca} alt="Camisa Marrom" style={styles.image} />
        </div>
      </div>

      <div style={styles.smallCardsWrapper}>
        <div style={styles.smallCardsContainer}>
          <div style={styles.smallCard}>
            <img src={CamisaIcon} alt="Camisas" style={styles.smallCardIcon} />
          </div>
          <div style={styles.smallCard}>
            <img src={CalcaIcon} alt="Calças" style={styles.smallCardIcon} />
          </div>
          <div style={styles.smallCard}>
            <img
              src={AcessorioIcon}
              alt="Acessórios"
              style={styles.smallCardIcon}
            />
          </div>
          <div style={styles.smallCard}>
            <span style={styles.smallCardText}>CAMISAS</span>
          </div>
          <div style={styles.smallCard}>
            <span style={styles.smallCardText}>CALÇAS</span>
          </div>
          <div style={styles.smallCard}>
            <span style={styles.smallCardText}>ACESSÓRIOS</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginTop: 80,
    position: "relative",
    padding: "30px 0",
    textAlign: "center",
  },
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "30px",
  },
  imagesContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "30px",
  },
  card: {
    width: "400px",
    height: "500px",
    backgroundColor: "#ABABAB",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    objectFit: "contain",
  },
  smallCardsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  smallCardsContainer: {
    marginTop: "50px",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // 3 colunas
    gridTemplateRows: "repeat(2, auto)", // 2 linhas
    gap: "30px", // Espaçamento entre os cartões
    padding: "0 30px",
    maxWidth: "1200px", // Para centralizar melhor a grid e limitar a largura
  },
  smallCard: {
    width: "300px",
    height: "100px",
    backgroundColor: "#0A2054",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    marginLeft: "50px", // Espaçamento à esquerda
    marginRight: "50px", // Espaçamento à direita
  },

  smallCardIcon: {
    height: "150px",
    marginBottom: "10px",
  },
  smallCardText: {
    fontSize: "18px",
    fontWeight: "bold",
  },
};

export default Destaques;
