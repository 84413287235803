import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cabecalho from "./componentes/paginaInicial/Cabecalho";
import CarouselComponent from "./componentes/paginaInicial/CarouselComponent";
import Destaques from "./componentes/paginaInicial/Destaques";
import Produtos from "./componentes/paginaInicial/Produtos";
import Rodape from "./componentes/paginaInicial/Rodape";
import PaginaProduto from "./componentes/paginaInicial/PaginaProduto";
import "./index.css";

function App() {
  return (
    <Router>
      <Cabecalho />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <CarouselComponent />
              <Destaques />
              <Produtos />
            </>
          }
        />
        <Route path="/produto/:id" element={<PaginaProduto />} />
      </Routes>
      <Rodape />
    </Router>
  );
}

export default App;
