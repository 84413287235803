import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  InputBase,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import BagIcon from "../imagens/shopping-bag.png";

function Cabecalho() {
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  return (
    <div className="header" style={styles.header}>
      <div style={styles.leftSection}>
        <IconButton onClick={toggleDrawer(true)} style={styles.menuButton}>
          <MenuIcon fontSize="large" />
        </IconButton>
        <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
          <div style={styles.drawerContainer}>
            <div style={styles.drawerHeader}>
              <IconButton
                onClick={toggleDrawer(false)}
                style={styles.closeButton}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>

            <List style={styles.drawerList}>
              <ListItem button>
                <ListItemText primary="Produtos" style={styles.drawerText} />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Ofertas" style={styles.drawerText} />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Novidades" style={styles.drawerText} />
              </ListItem>
            </List>
          </div>
          <div style={styles.searchContainer}>
            <InputBase
              placeholder="Pesquise em nossa loja"
              style={styles.searchInput}
              startAdornment={
                <IconButton style={styles.searchIcon}>
                  <SearchIcon />
                </IconButton>
              }
            />
          </div>
        </Drawer>
      </div>

      <div style={styles.centerSection}>
        <div style={styles.textContainer}>
          <h1 style={styles.title}>ApolloGod</h1>
          <p style={styles.subtitle}>streetwear</p>
        </div>
      </div>

      <div style={styles.rightSection}>
        {searchOpen ? (
          <div style={styles.searchBar}>
            <InputBase
              placeholder="Pesquise em nossa loja."
              style={styles.searchInput}
            />
            <IconButton onClick={toggleSearch}>
              <CloseIcon fontSize="large" style={styles.icon} />
            </IconButton>
          </div>
        ) : (
          <IconButton onClick={toggleSearch}>
            <SearchIcon fontSize="large" style={styles.icon} />
          </IconButton>
        )}
      </div>
    </div>
  );
}

const styles = {
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 80px",
    height: "80px",
    backgroundColor: "#f4f4f4",
    borderBottom: "2px solid #ccc",
    fontFamily: "'Times New Roman', Times, serif",
  },
  leftSection: {
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    color: "#333",
  },
  centerSection: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  textContainer: {
    textAlign: "center",
  },
  title: {
    margin: 0,
    fontSize: "32px",
    letterSpacing: "2px",
    fontWeight: "bold",
    color: "#333",
  },
  subtitle: {
    margin: 0,
    fontSize: "16px",
    fontStyle: "italic",
    color: "#777",
  },
  rightSection: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "#333",
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: "20px",
    padding: "0 10px",
  },
  searchInput: {
    padding: "8px",
    width: "200px",
  },
  drawerContainer: {
    width: 300,
    backgroundColor: "#f9f9f9",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  drawerHeader: {
    padding: "16px",
    borderBottom: "1px solid #ddd",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    color: "#333",
  },
  drawerList: {
    flexGrow: 1,
  },
  drawerText: {
    fontFamily: "'Times New Roman', Times, serif",
    fontSize: "18px",
    padding: "15px 20px",
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
  searchContainer: {
    padding: "16px",
    borderBottom: "1px solid #ddd",
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
  },
};

export default Cabecalho;
