// src/componentes/paginaInicial/CustomArrows.js

import React from "react";
import { IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        left: 10, // Ajuste a distância do lado esquerdo conforme necessário
        zIndex: 1,
        backgroundColor: "transparent", // Fundo transparente
        border: "none", // Remove a borda
        boxShadow: "none", // Remove a sombra
        transform: "translateY(-50%)",
      }}
    >
      <ArrowBackIosIcon style={{ color: "#333", fontSize: "24px" }} />{" "}
      {/* Ajuste a cor e o tamanho do ícone conforme necessário */}
    </IconButton>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      style={{
        position: "absolute",
        top: "50%",
        right: 10, // Ajuste a distância do lado direito conforme necessário
        zIndex: 1,
        backgroundColor: "transparent", // Fundo transparente
        border: "none", // Remove a borda
        boxShadow: "none", // Remove a sombra
        transform: "translateY(-50%)",
      }}
    >
      <ArrowForwardIosIcon style={{ color: "#333", fontSize: "24px" }} />{" "}
      {/* Ajuste a cor e o tamanho do ícone conforme necessário */}
    </IconButton>
  );
};

export { PrevArrow, NextArrow };
