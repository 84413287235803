import React from "react";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Email,
  Phone,
} from "@mui/icons-material";
import { Button } from "@mui/material";

const Rodape = () => {
  return (
    <div style={styles.container}>
      {/* Redes Sociais */}
      <div style={styles.section}>
        <h3 style={styles.heading}>Redes Sociais</h3>
        <div style={styles.icons}>
          <Facebook style={styles.icon} />
          <Instagram style={styles.icon} />
          <LinkedIn style={styles.icon} />
        </div>
      </div>

      {/* Informações */}
      <div style={styles.section}>
        <h3 style={styles.heading}>Informações</h3>
        <ul style={styles.list}>
          <li>
            <Button style={styles.button} variant="text">
              Rastreio
            </Button>
          </li>
          <li>
            <Button style={styles.button} variant="text">
              Informações sobre Envio
            </Button>
          </li>
          <li>
            <Button style={styles.button} variant="text">
              Política de Trocas e Devoluções
            </Button>
          </li>
          <li>
            <Button style={styles.button} variant="text">
              Quem Somos
            </Button>
          </li>
          <li>
            <Button style={styles.button} variant="text">
              Política de Privacidade
            </Button>
          </li>
          <li>
            <Button style={styles.button} variant="text">
              Termos de serviço
            </Button>
          </li>
        </ul>
      </div>

      {/* Contato */}
      <div style={styles.section}>
        <h3 style={styles.heading}>Contato</h3>
        <p style={styles.contactText}>
          Nosso SAC funciona de Segunda à Sexta-feira das 9:00h às 17:30h.
        </p>
        <div style={styles.contact}>
          <Email style={styles.icon} />
          <span style={styles.contactText}>contato@apollogod.com.br</span>
        </div>
        <div style={styles.contact}>
          <Phone style={styles.icon} />
          <span style={styles.contactText}>(11) 99999-9999</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-around",
    padding: "10px 20px", // Reduzi o padding vertical
    backgroundColor: "#f0f0f0",
    borderTop: "1px solid #ccc",
  },
  section: {
    flex: 1,
    padding: "5px", // Reduzi o padding interno
  },
  heading: {
    margin: "10px 0", // Reduzi o espaçamento vertical dos títulos
    fontSize: "18px",
  },
  icons: {
    display: "flex",
    gap: "8px", // Mantive o espaçamento horizontal entre os ícones
  },
  icon: {
    fontSize: "24px", // Reduzi o tamanho dos ícones
    cursor: "pointer",
  },
  list: {
    listStyleType: "none",
    padding: 0,
    margin: 0, // Removi margens para otimizar espaço
  },
  button: {
    color: "black",
    fontFamily: "Glacial Indifference",
    padding: "6px 0", // Reduzi o espaçamento vertical dos botões
    textAlign: "left",
  },
  contact: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px", // Reduzi o espaçamento entre os itens de contato
  },
  contactText: {
    marginLeft: "8px",
    fontSize: "14px", // Ajustei o tamanho do texto de contato
  },
};

export default Rodape;
