import React, { useEffect, useState } from "react";
import CamisaTeste from "../imagens/camisaProdutos.png";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const Produtos = ({ limite }) => {
  const navigate = useNavigate();

  const produtos = [
    {
      id: 1,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 2,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 3,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 4,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 5,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 6,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 7,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 8,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 9,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 10,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 11,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 12,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 13,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 14,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 15,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
    {
      id: 16,
      nome: "Camiseta lisa oversized de algodão",
      preco: "R$ 69,99 à vista",
      parcelas: "10x no cartão sem juros",
    },
  ];

  // Função para lidar com o clique no produto
  const handleProdutoClick = (produtoId) => {
    navigate(`/produto/${produtoId}`);
  };

  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Tempo da animação em milissegundos
  }, []);

  // Renderiza a lista de produtos
  return (
    <div style={styles.container}>
      {produtos.slice(0, limite).map((produto) => (
        <div
          key={produto.id}
          style={styles.produto}
          onClick={() => handleProdutoClick(produto.id)}
          data-aos="fade-up"
          onMouseEnter={() => setHovered(produto.id)} // Define o produto em hover
          onMouseLeave={() => setHovered(null)} // Remove o hover ao sair
        >
          <div style={styles.imagem}>
            <img
              src={CamisaTeste}
              alt="Camisas"
              style={hovered === produto.id ? styles.imagemHover : {}}
            />
          </div>
          <div style={styles.nome}>{produto.nome}</div>
          <div style={styles.preco}>{produto.preco}</div>
          <div style={styles.parcelas}>{produto.parcelas}</div>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "20px",
    padding: "20px",
  },
  produto: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "20px",
    borderRadius: "10px",
    cursor: "pointer",
    transition: "transform 0.3s ease", // Suaviza a transição ao passar o mouse
  },
  imagem: {
    width: "323px",
    height: "397px",
    backgroundColor: "#EEEEEE",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden", // Garante que a imagem não ultrapasse o contêiner ao aplicar o zoom
  },
  imagemHover: {
    transform: "scale(1.3)", // Zoom na imagem
    transition: "transform 0.3s ease", // Transição suave
  },
  nome: {
    fontWeight: "bold",
    marginBottom: "10px",
  },
  preco: {
    color: "#333",
    marginBottom: "5px",
  },
  parcelas: {
    color: "#777",
    fontSize: "14px",
  },
};

export default Produtos;
