import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CamisaMarrom from "../imagens/camisetaMarromNova.png";
import { PrevArrow, NextArrow } from "./CustomArrows"; // Atualize o caminho conforme necessário

function CarouselComponent() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider {...settings}>
      <div>
        <img src={CamisaMarrom} alt="Camisa Cinza" style={styles.image} />
      </div>
      <div>
        <img src={CamisaMarrom} alt="Camisa Marrom" style={styles.image} />
      </div>
      <div>
        <img
          src={CamisaMarrom}
          alt="Camisa Marrom Verso"
          style={styles.image}
        />
      </div>
    </Slider>
  );
}

const styles = {
  image: {
    width: "100%",
    height: "500px",
    objectFit: "cover",
  },
};

export default CarouselComponent;
