import React from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import VerifiedIcon from "@mui/icons-material/Verified";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Produtos from "./Produtos";
import ModeloCamiseta from "../imagens/imagemPaginaProduto.png";
import { useParams } from "react-router-dom";

const style = {
  container: {
    padding: "20px",
  },
  image: {
    width: "100%",
    position: "relative",
  },
  iconButton: {
    position: "absolute",
    top: 10,
    right: 10,
  },
  detailsContainer: {
    marginTop: "20px",
  },
  select: {
    width: "300px",
  },
  button: {
    width: "300px",
    background: "#0A2054",
  },
  iconsContainer: {
    marginTop: "20px",
  },
  recommendationTitle: {
    marginTop: "30px",
  },
  recommendationContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
};

const produtos = [
  {
    id: 1,
    nome: "Camiseta lisa oversized de algodão",
    preco: "R$ 69,99 à vista",
    parcelas: "10x no cartão sem juros",
  },
  {
    id: 2,
    nome: "Camiseta estampada premium",
    preco: "R$ 89,99 à vista",
    parcelas: "8x no cartão sem juros",
  },
  // Outros produtos...
];

const PaginaProduto = () => {
  const { id } = useParams();
  const produto = produtos.find((p) => p.id === parseInt(id));

  if (!produto) {
    return <div>Produto não encontrado.</div>;
  }

  return (
    <Grid container spacing={2} style={style.container}>
      {/* Product Image */}
      <Grid item xs={12} sm={6}>
        <img src={ModeloCamiseta} alt={produto.nome} style={style.image} />
        <IconButton style={style.iconButton}>
          <FavoriteBorderIcon />
        </IconButton>
      </Grid>

      {/* Product Details */}
      <Grid item xs={12} sm={6}>
        <Typography variant="overline">oversized - lisa - masculina</Typography>
        <Typography variant="h5">{produto.nome}</Typography>
        <Typography variant="h6" color="green" style={{ marginTop: "10px" }}>
          {produto.preco}
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginTop: "20px" }}
        >
          Ver 4 opções de cores disponíveis
        </Typography>

        {/* Botões e Select alinhados verticalmente */}
        <Grid container direction="column" spacing={2}>
          <Grid item>
            {/* Select Options */}
            <Select defaultValue="" displayEmpty style={style.select}>
              <MenuItem value="" disabled>
                Ver opções de tamanho
              </MenuItem>
              <MenuItem value="P">P</MenuItem>
              <MenuItem value="M">M</MenuItem>
              <MenuItem value="G">G</MenuItem>
              <MenuItem value="GG">GG</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" style={style.button}>
              Adicionar ao carrinho
            </Button>
          </Grid>
        </Grid>

        {/* Icons */}
        <Grid
          container
          direction="column"
          spacing={2}
          style={style.iconsContainer}
        >
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <LocalShippingIcon />
              </Grid>
              <Grid item>
                <Typography variant="body2">Frete grátis</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <VerifiedIcon />
              </Grid>
              <Grid item>
                <Typography variant="body2">Garantia de Qualidade</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Product Recommendations */}
      <Grid item xs={12} style={style.recommendationTitle}>
        <Typography variant="h6" align="center">
          Mais recomendações para você
        </Typography>
        <Grid container style={style.recommendationContainer}>
          <IconButton>
            <ArrowBackIosIcon />
          </IconButton>
          <Grid
            container
            item
            xs={10}
            spacing={2}
            justifyContent="space-between"
          >
            <Produtos limite={3} />
          </Grid>
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaginaProduto;
